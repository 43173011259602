import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Modal from "components/modal/Modal";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import { createDOBucketName } from "utils/DO-Spaces";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import { ReactComponent as HappyBirthday } from "assets/icons/birthday/happybirthday.svg";
import { ReactComponent as BirthdayHat } from "assets/icons/birthday/hat.svg";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import IconButton from "components/buttons/icon-button/IconButton";
import AdminOrderReceipt from "pages/admin/admin-pages/admin-order/admin-order-map/admin-order-receipt/AdminOrderReceipt";
import {
  checkDateEqualCurrentDate,
  concatFirstNameWithLastName,
  convertDateToShortFormat,
} from "utils/helpers";
import useLanguage from "utils/hooks/useLanguage";
import useAsync from "utils/hooks/useAsync";
import {
  getGuestDetailsMetadata,
  getGuestOrderDetails,
} from "utils/api/services/guest";
import { STORE_NAMES } from "utils/constants/redux";
import Spinner from "components/elements/spinner/Spinner";

import "./AdminOrderGuestInfoModal.scss";

const AdminOrderGuestInfoModal = ({
  mainElementRef,
  openSlide,
  handleCloseModal,
  guestId,
}) => {
  const { t } = useTranslation();
  const { displayDataByLanguage } = useLanguage();
  const [guest, setGuests] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const AdminOrderGuestInfoModalHeader = (
    <div className="AdminOrderGuestInfoModalHeader">
      <h3 className="SemiBold">{t("modal.guestProfile")}</h3>
      <IconButton onClick={handleCloseModal} svgComponent={<IconClose />} />
    </div>
  );
  const checkDate = checkDateEqualCurrentDate(guest.dateOfBirth);
  const guestDetail = [
    {
      info: convertDateToShortFormat(guest.dateOfBirth) || "-",
      label: t("inputs.dateOfBirth"),
    },
    {
      info: guest.gender,
      label: t("auth.gender"),
    },
  ];
  const formatNumber = (value) => {
    if (Number.isInteger(value)) {
      return value.toFixed(2);
    }
    return value;
  };

  const { loading: getGuestLoading, execute: executeGetGuestById } = useAsync(
    getGuestDetailsMetadata,
    {
      onSuccess: (response) => {
        setGuests(response.data);
      },
    }
  );
  const {
    loading: getGuestOrderLoading,
    execute: executeGetGuestOrderDetails,
  } = useAsync(getGuestOrderDetails, {
    onSuccess: (response) => {
      setAllOrders(response.data.data);
    },
  });
  useEffect(() => {
    if (guestId && openSlide) {
      executeGetGuestOrderDetails({
        businessId: business.id,
        guestId,
      });
      executeGetGuestById({ businessId: business.id, guestId });
    }
  }, [guestId, business, openSlide]);

  const allCurrencies = useSelector(
    (state) => state[STORE_NAMES.app].currencies
  );

  const currency = allCurrencies.find((curr) => curr.id === guest.currencyId);

  const AdminOrderGuestInfoModalBody = (
    <div className="AdminOrderGuestInfoModalBody">
      <div className="GuestMainInfo">
        <div className="GuestProfile">
          {checkDate && <BirthdayHat className="BirthdayHat" />}
          <ImageWithPlaceholder
            imageSource={createDOBucketName(guest?.profilePic)}
            placeholder={IMG_GUEST}
            alt="logo"
          />
          <h3 className="Medium">
            {concatFirstNameWithLastName({
              firstName: guest.firstName,
              lastName: guest.lastName,
            }) || `${t("dashboard.guest.guest")} ${guest?.id}`}
          </h3>
        </div>
        {checkDate && <HappyBirthday className="HappyBirthday" />}
      </div>
      <div className="GuestDetailInfoContainer">
        {guestDetail.map((guest, index) => {
          return (
            <div key={index}>
              <h5 className="Medium">{guest?.info}</h5>
              <h6 className="Medium">{guest?.label}</h6>
            </div>
          );
        })}
      </div>
      <div className="GuestTotalInfoContainer">
        <div>
          <h5 className="Medium">{guest.visitCount}</h5>
          <h6 className="Medium">{t("modal.totalVisits")}</h6>
        </div>
        <div>
          {guest.averageAmount ? (
            <h5 className="Medium">{`${currency?.symbol} ${formatNumber(
              guest.averageAmount
            )}`}</h5>
          ) : (
            <h5 className="Medium">0</h5>
          )}

          <h6 className="Medium">{t("modal.averageAmount")}</h6>
        </div>
      </div>
      <h3 className="SemiBold">
        {t("modal.topOrder").replace("{{count}}", "3")}
      </h3>
      <div className="GuestTopOrderContainer">
        {Array.from({ length: 3 }).map((_, index) => {
          const topOrder = guest?.topThreeOrders?.[index];
          return (
            <div key={index}>
              {topOrder ? (
                <h5 className="Medium">{`${
                  topOrder.count
                }x ${displayDataByLanguage(topOrder.item.name)}`}</h5>
              ) : (
                <span>{t("orderItem.empty")}</span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );

  const filteredOrders = allOrders?.filter(
    (allOrder) => allOrder.orderItems.length > 0
  );

  const AdminOrderGuestInfoModalFooter = (
    <div className="AdminOrderGuestInfoModalFooter">
      <h3 className="SemiBold">{`${t("modal.visitHistory")} (${
        filteredOrders?.length
      })`}</h3>
      {filteredOrders?.map((orderReceipt, index) => {
        return <AdminOrderReceipt key={index} orderReceipt={orderReceipt} />;
      })}
    </div>
  );

  return (
    <div className="AdminOrderGuestInfoContainer">
      <Modal
        header={AdminOrderGuestInfoModalHeader}
        body={
          getGuestLoading || getGuestOrderLoading ? (
            <div className="AdminOrderGuestInfoModalBodyWrapper">
              <Spinner />
            </div>
          ) : (
            AdminOrderGuestInfoModalBody
          )
        }
        footer={
          !getGuestLoading &&
          !getGuestOrderLoading &&
          AdminOrderGuestInfoModalFooter
        }
        mainElementRef={mainElementRef}
        openSlide={openSlide}
      />
    </div>
  );
};

AdminOrderGuestInfoModal.propTypes = {
  mainElementRef: PropTypes.object,
  openSlide: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  guestId: PropTypes.number.isRequired,
};

export default AdminOrderGuestInfoModal;
